




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import RIDCService from "@/services/request/RIDCService";
import RIDC_DTO from "@/dto/request/ridc/RIDC_DTO";
import CreateRIDCContractorPayload from "@/dto/request/ridc/CreateRIDCContractorPayload";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import { ifValid, processError } from "@/utils/ComponentUtils";
import RouteNames from '@/router/RouteNames';
import CreateRIDCPayload from "@/dto/request/ridc/CreateRIDCPayload";

const AppModule = namespace("App");

@Component({
  components: { PhoneInput, PortalInput }
})

export default class PublicRIDC extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop({default: null})
  private payload!: CreateRIDCPayload

  request: RIDC_DTO | null = null;
  private newContractor = new CreateRIDCContractorPayload();

  mounted() {
    if (this.$route.name === RouteNames.RIDC_PUBLIC) {
      this.loadRequest();
    }
  }

  loadRequest() {
    this.startLoading();
    if (this.$route.name === RouteNames.RIDC_PUBLIC) {
      this.selectGetRequest(RIDCService.getByPublicId(this.id))
    } else {
      this.selectGetRequest(RIDCService.getById(Number(this.id)))
    }
  }

  selectGetRequest(request: Promise<{ data: RIDC_DTO }>) {
    request.then(
      ok => {
        this.request = ok.data;
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  addContractor() {
    ifValid(this, () => {
      if (!this.currentRequest) {
        return;
      }
      this.startLoading();
      if (this.$route.name === RouteNames.RIDC_PUBLIC) {
        RIDCService.addContractor(this.id, this.newContractor).then(
          ok => {
            this.stopLoading();
            this.loadRequest();
          },
          err => {
            this.stopLoading();
            processError(err, this);
          }
        )
      } else {
        this.$emit('add-contractor', this.newContractor)
        this.stopLoading();
      }
    })
  }

  completeFillingContractors() {
    RIDCService.completeFillingContractors(this.id).then(
      ok => {
        this.stopLoading();
        this.loadRequest();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  get id() {
    return this.$route.params.id;
  }

  get isPhoneFilled(){
    return !!this.newContractor.phone;
  }

  get isEmailFilled(){
    return !!this.newContractor.email;
  }

  get currentRequest() {
    if (this.$route.name === RouteNames.RIDC_PUBLIC) {
      return this.request
    } else {
      return this.payload
    }
  }
}
